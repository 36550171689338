import './App.css';

import speechBubblesSrc from './speech-bubbles.png'
import mastodonSrc from './mastodon.png'

function App() {
  return (
    <section className="app">
      <main className="app-content">
        <header className="app-header">
            <h1 className="app-logo">
              <a href="https://acorn.net">
                Acorn.net
              </a>
            </h1>
        </header>
      </main>
      <footer className="app-footer">
        <a href="https://mastodon.social/@acornnetwork" className="feed-link footer-link"><img className="footer-icon feed-icon" src={mastodonSrc} alt="feed"/></a>
        <a href="https://chat.acorn.net" className="chat-link footer-link"><img className="footer-icon chat-icon" src={speechBubblesSrc} alt="chat"/></a>
      </footer>
    </section>
  );
}

export default App;
